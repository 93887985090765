[
    {
      "command":"help",
      "responseType":"list",
      "value":[
        "<code>about</code> : Afficher des informations sur moi",
        "<code>clear</code> : Nettoyer le terminal",
        "<code>education</code> : Afficher la liste de mes formations suivies",
        "<code>experiences</code> : Afficher la liste de mes expériences professionnelles",
        "<code>get cv</code> : Télécharger mon CV",
        "<code>help</code> : Afficher l'aide",
        "<code>hobbies</code> : Afficher la liste de mes hobbies",
        "<code>dark/light</code> : Changer le theme en sombre ou en clair",
        "<em>Vous pouvez utiliser la touche TAB pour terminer une commande</em>",
        "<em>Vous pouvez trouver les anciennes commandes avec les flèches haut et bas</em>"
      ]
    },
    {
      "command":"about",
      "responseType":"code",
      "value":[
        "{",
        "   \"Nom\" : \"Mathis DESLANDES\",",
        "   \"Poste\" : \"IT Technicien & Développeur Web\",",
        "   \"Experience\" : \"2 ans\",",
        "   \"Ville\" : \"Igny, IDF, France\"",
        "}"
      ]
    },
    {
      "command":"experiences",
      "responseType":"table",
      "headers":[
        "Date",
        "Entreprise",
        "Description",
        "Lieu"
      ],
      "rows":[
        [
          "2015<br/>2016",
          "Gendarmerie Nationale<br/><em>GGD 91</em>",
          "Stage de découverte de 3ème à la Gendarmerie Nationale de Palaiseau",
          "Palaiseau<br/>91120 Essonne"
        ],
        [
          "08/2019",
          "Mairie du Plessis-Robinson<br/><em>Service Courrier</em>",
          "Job été au service courrier de la mairie du Plessis-Robinson",
          "Le Plessis-Robinson<br/>92350 Hauts-de-Seine"
        ],
        [
          "11/2019<br/>11/2021",
          "Mairie de Clamart<br/><em>Service Informatique</em>",
          "Apprenti technicien SRT au service informatique de la mairie de Clamart",
          "Clamart<br/>92140 Hauts-de-Seine"
        ],
        [
          "11/2021<br/>11/2022",
          "Mairie de Clamart<br/><em>Service Informatique</em>",
          "Contrat de projet en tant que pilote technique du projet numérique éducatif</br>au service informatique de la mairie de Clamart",
          "Clamart<br/>92140 Hauts-de-Seine"
        ]
      ]
    },
    {
      "command":"hobbies",
      "responseType":"list",
      "value":[
        "Institution: Gendarmerie",
        "Programmation: HTML, CSS, JS, PHP",
        "Autre: Aéronautique, Plateformes de simulation"
      ]
    },
    {
      "command":"education",
      "responseType":"table",
      "headers":[
        "Formation",
        "Obtention",
        "Année",
        "Lieu"
      ],
      "rows":[
        [
          "BREVET DES COLLEGES",
          "Sans options</br>Obtenue avec mention ASSEZ BIEN",
          "2012 - 2016",
          "Collège Emile Zola</br><em>IGNY</em>"
        ],
        [
          "BAC TECHNOLOGIQUE STI2D",
          "Option SIN (Systèmes d’Information et Numérique)</br>Obtenue avec mention ASSEZ BIEN",
          "2016 - 2019",
          "Lycée Parc de Vilgénis</br><em>MASSY</em>"
        ],
        [
          "BTS SERVICE INFORMATIQUE</br>AUX ORGANISATIONS",
          "Option SLAM (Solutions Logicielles et Applications Métiers)</br>Obtenue avec une moyenne de 13",
          "2019 - 2021",
          "Faculté des Métiers de l’Essonne</br><em>MASSY</em>"
        ]
      ]
    },
    {
      "command":"projects",
      "responseType":"list",
      "value":[
        "A définir"
      ]
    }
]
